import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import { useConfig } from 'src/common/hooks';
import { trackSocialButtonClicked } from 'src/common/tracking/trackingUtils';
import { SignInSource } from 'src/components/LoginModal/ExternalSignInButtons';
import { loginWithGoogle } from 'src/modules/Session/Actions';
import { LoginSignUpWithGoogleClicked } from 'src/modules/Session/GTMActions';

interface Props {
  gtmData?: {
    signup: string;
    login: string;
  };
  signInSource: SignInSource;
}

export const SignInWithGoogle: React.FC<React.PropsWithChildren<Props>> = ({
  gtmData: { login, signup },
  signInSource,
}) => {
  const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const { googleClientId } = useConfig({ googleClientId: 'GOOGLE_CLIENT_ID' });
  const dispatch = useDispatch();
  const router = useRouter();
  const apolloClient = useApolloClient();
  const onSuccess = useCallback(
    (res: any) =>
      dispatch(
        loginWithGoogle(
          res.credential,
          {
            login,
            signup,
          },
          signInSource,
          apolloClient
        )
      ),
    [dispatch, login, signup, signInSource, apolloClient]
  );

  const handleGoogleSignIn = useCallback(
    (res: CredentialResponse) => {
      onSuccess(res);
    },
    [onSuccess]
  );

  useEffect(() => {
    const initializeGsi = () => {
      if (!window.google || gsiScriptLoaded) {
        return;
      }

      setGsiScriptLoaded(true);

      window.google.accounts.id.initialize({
        client_id: googleClientId,
        callback: handleGoogleSignIn,
      });

      // @ts-ignore
      window.google.accounts.id.renderButton(buttonRef.current, {
        type: 'icon',
        shape: 'circle',
        click_listener: () => {
          trackSocialButtonClicked({
            type: 'google',
            page: router.asPath.includes('signup') ? 'signup' : 'login',
          });
          return dispatch(LoginSignUpWithGoogleClicked());
        },
      });
    };

    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.onload = initializeGsi;
    script.async = true;
    script.defer = true;
    script.id = 'google-client-script';
    document.querySelector('body')?.appendChild(script);

    return () => {
      // Cleanup function that runs when component unmounts
      window.google?.accounts.id.cancel();
      document.getElementById('google-client-script')?.remove();
    };
  }, [
    dispatch,
    googleClientId,
    gsiScriptLoaded,
    handleGoogleSignIn,
    router.asPath,
  ]);

  return <div ref={buttonRef} />;
};
